<template>
  <!-- 登录页面 -->
  <div class="login_wrapper">
    <div class="titleBar" v-if="$native.isAndroid()"></div>

    <!-- 登录logo -->
    <div class="login_logo">
      <img src="../assets/images/login.png" alt />
    </div>

    <!-- 登录表单 -->
    <div class="login_form_box">
      <div class="login_form">
        <div class="form_tenantName" v-if="!isApp">
          <!-- <input type="text" placeholder="请输入手机号码" /> -->
          <input
            v-model="tenantName"
            placeholder="请输入租户名"
            type="text"
            maxlength="16"
            οninput="if(value.length>11)value=value.slice(0,16)"
          />
        </div>
        <div class="form_phone">
          <!-- <input type="text" placeholder="请输入手机号码" /> -->
          <input
            v-model="username"
            placeholder="请输入用户名"
            type="text"
            maxlength="16"
            οninput="if(value.length>11)value=value.slice(0,16)"
          />
        </div>
        <div class="form_code">
          <input
            v-model="password"
            placeholder="请输入密码"
            type="text"
            maxlength="16"
            οninput="if(value.length>6)value=value.slice(0,16)"
            v-if="showPassword"
          />
          <input
            v-model="password"
            placeholder="请输入密码"
            type="password"
            maxlength="16"
            οninput="if(value.length>6)value=value.slice(0,16)"
            v-else
          />
          <!-- <input type="text" placeholder="请输入短信验证码" v-model="code" /> -->
          <div
            class="code_eye"
            @touchstart.prevent="changePasswork(true)"
            @touchend.prevent="changePasswork(false)"
          >
            <van-icon name="eye-o" />
          </div>
        </div>
      </div>

      <div @click="onlogin" class="login_btn">
        <span>登 录</span>
      </div>
    </div>

    <div class="login_signature">
      <div class="signature_icon">
        <img class="auto_img" src="../assets/images/ic_company.png" alt="" />
      </div>
      <div class="signature_title">
        新中望信息科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Dialog } from "vant";
export default {
  data: () => ({
    username: "", //用户名
    password: "", //密码
    tenantName: "", //租户名
    showPassword: false //显示密码明文
  }),
  methods: {
    ...mapMutations({
      setToken: "account/setToken",
      setExpireTime: "account/setExpireTime",
      setAccount: "account/setAccount",
      setAvatar: "account/setAvatar",
      setUserId: "account/setUserId",
      setPhone: "account/setPhone",
      setUserName: "account/setUserName",
      setCity: "account/setCity",
      setType: "account/setType"
    }),

    changePasswork(bool) {
      this.showPassword = bool;
    },

    //记录登录成功后数据
    saveLoginData(data) {
      this.setToken(data.token);
      this.setExpireTime(data.expireTime);
      this.setAccount(data.employee.account);
      this.setAvatar(data.employee.avatar);
      this.setUserId(data.employee.id);
      this.setPhone(data.employee.phone);
      this.setUserName(data.employee.name);
      this.setCity(data.employee.city);
      this.setType(data.employee.type);
    },

    onlogin() {
      if (this.password.length < 6) {
        Dialog.alert({
          title: "系统提示",
          message: "密码长度为6至16位"
        });
        return;
      }
      this.login(this.tenantName, this.username, this.$md5(this.password));
    },

    /**
     * 封装登录函数
     * tenantName 租户名
     * userName 用户名
     * password 密码
     */
    login(tenantName, userName, password) {
      // this.$post(this.$config.LOGIN, {
      //   tenantName,
      //   userName,
      //   password
      // })
      this.$api
        .login({
          tenantName,
          userName,
          password
        })
        .then(res => {
          this.$db.save("_c", tenantName);

          let data = res["data"]["data"];

          console.log("登錄后 data ==> ", JSON.stringify(data));

          this.saveLoginData(data);
          console.log("登錄后 saveLoginData ==> ", data);

          //初始化Native请求地址
          this.$jsBridge.initHttpRequestBaseUrl();
          console.log("登錄后 initHttpRequestBaseUrl ==> ", data);

          //同步Native用户信息
          this.$jsBridge.logined({
            ...data,
            account: userName,
            password
          });
          console.log("登錄后 logined ==> ", data);

          //储存账号密码
          this.$db.save("_a", userName);
          this.$db.save("_b", password);

          console.log("登錄后 db ==> ", userName, password);
          this.$tools.closeLoading();

          //判断是否点击消息进入
          let queryData = this.$route.query;
          let toMsg = null;
          console.log("跳转路由前 == > ", JSON.stringify(queryData));
          if ("msg" in queryData) {
            toMsg = true;
          }
          console.log("跳转路由前 == > ", toMsg);
          this.$router.replace({
            name: "Index",
            params: {
              toMsg
            }
          });
        });
    }
  },

  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  },

  watch: {
    username() {
      this.username = this.username.replace(/[^A-Za-z]/g, "");
    },
    password() {
      this.password = this.password.replace(/[^\w]/g, "");
    }
  },

  created() {
    this.$jsBridge.changeStatusBarStyle("black");
    this.$tools.showLoading();
    document.title = "智即停综合运营 - 登录";

    //从路由获取Native传来的租户名
    this.tenantName =
      this.$db.get("_c", "") || this.$route.query.tenantName || "";
  },

  mounted() {
    //已经保存登录账号密码，自动登录
    let username = this.$db.get("_a", null);
    let password = this.$db.get("_b", null);

    if (username && password && this.tenantName) {
      console.log("登录前 == >", username, password, this.tenantName);

      this.login(this.tenantName, username, password);
    } else {
      this.$tools.entering();
    }
  }
};
</script>

<style lang="less" scoped>
.titleBar {
  background-color: #fff;

  &::before {
    background-color: #fff;
  }
}

.login_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
}

.login_logo {
  flex: 1;
  min-height: 180px;
  // margin-top: 60px;
  // margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    display: block;
    width: 200px;
    height: auto;
  }
}

.login_form_box {
  width: 100%;
  flex: 1;
  // min-height : 300px ;
}

.login_form {
  width: 100%;
  background-color: #edf0f7;
  border-radius: 10px;
  padding: 0 14px;

  > div {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;

    &.form_phone,
    &.form_tenantName {
      border-bottom: 1px solid rgba(7, 17, 27, 0.1);
    }

    &.form_code {
      position: relative;

      .code_btn {
        position: absolute;
        right: 0px;
        top: 12px;
        height: 36px;
        width: 80px;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        border: 1px solid #3881dd;
        color: #3881dd;
        border-radius: 4px;

        &.disabled {
          border: 1px solid #999;
          color: #999;
          pointer-events: none;
        }
      }

      .code_eye {
        font-size: 22px;
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    > input {
      width: 100%;
      height: 40px;
      border: 0;
      outline: 0;
      background-color: transparent;
      font-size: 14px;
    }
  }
}

.login_btn {
  width: 100%;
  height: 40px;
  background-color: #143288;
  margin-top: 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  flex: 0 0 60px;
}

.login_signature {
  // flex: 0 0 60px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .signature_icon {
    width: 30px;
    margin-bottom: 6px;
  }

  .signature_title {
    font-size: 12px;
  }
}
</style>
